import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Homepage from './pages/Homepage/Homepage';
import { ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Support from './pages/Support/Support';
import Privacy from './pages/Privacy/Privacy';
import TermsAndConditions from './pages/TermsAndConditions/TermsAndConditions';
import DeleteAccount from './pages/DeleteAccount/DeleteAccount';
import About from './pages/About/About';

function App() {

  return (
    <div>
      <BrowserRouter>
      <Routes>
        <Route path='/' element={<Homepage/>}/>
        <Route path='/support' element={<Support/>}/>
        <Route path='/privacy' element={<Privacy/>}/>
        <Route path="/termsAndConditions" element={<TermsAndConditions/>}/>
        <Route path="/delete" element={<DeleteAccount/>}/>
        <Route path="/about" element={<About/>}/>
      </Routes>
      </BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        theme="colored"
      />
    </div>
  );
}

export default App;
